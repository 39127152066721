.loading {
  width: 100%;
  height: 100px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  background-color: rgba(226, 226, 226, 0.3);

  svg {
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
