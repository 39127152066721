.badge {
  background-color: #fff;
  padding: 6px 4px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 20px;
  width: 100%;
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: visible;
  position: relative;

  .image {
    position: relative;
    z-index: 10;

    height: 20px;
    width: 20px;
  }
  > span {
    flex: 3;
    text-align: center;
  }
}

.red-badge {
  background: linear-gradient(90deg, #feae00 0%, #ff1607 100%) !important;
  border: 1px solid var(--tofisav2-red-100) !important;
  > span {
    color: #fff !important;
  }
}

.orange-badge {
  border: 1px solid var(--primary-primary);

  > span {
    color: var(--primary-primary) !important;
  }
}

.blue-badge {
  background-color: #084298 !important;
  border: 1px solid #052c65;
  > span {
    color: #fff !important;
  }
}

.purple-badge {
  background-color: #f7ddff !important;
  border: 1px solid #8f07b6;

  > span {
    color: #8f07b6 !important;
  }
}

.green-badge {
  border: 1px solid #0fb607 !important;

  > span {
    color: #0fb607 !important;
  }
}

.light_blue-badge {
  border: 1px solid #006dc9 !important;

  > span {
    color: #006dc9 !important;
  }
}

.yellow-badge {
  border: 1px solid #b86712 !important;

  > span {
    color: #b86712 !important;
  }
}

// image styles
.red-image {
  left: -2px;
}

.blue-image {
  width: 23px !important;
  height: 17px !important;
  top: -1px !important;
}

.orange-image {
  width: 18px !important;
  height: 18px !important;
}

.purple-image {
  width: 16px !important;
  height: 16px !important;
}

.green-image {
  width: 17px !important;
  height: 17px !important;
  bottom: -1px;
}

.light_blue-image {
  width: 18px !important;
  height: 18px !important;
}

.yellow-image {
  width: 16px !important;
  height: 16px !important;
}

// pdp container
.purple-pdp-badge {
  width: 110px !important;

  span {
    text-align: center !important;
  }
}

.light_blue-pdp-badge {
  width: 100px !important;

  span {
    text-align: center !important;
  }
}

.orange-pdp-badge {
  width: 87px !important;

  span {
    text-align: center !important;
  }
}

.green-pdp-badge {
  width: 105px !important;

  span {
    text-align: center !important;
  }
}

// pdp images

.red-pdp-image {
  left: 1px !important  ;
}

.yellow-pdp-image {
  top: -4px;
  left: -2px !important  ;
  height: 24px !important;
  width: 24px !important;
}

.blue-pdp-image {
  height: 17px !important;
  width: 23px !important;
  top: -1px !important;
}

.purple-pdp-image {
  height: 16px !important;
  width: 16px !important;
}

.light_blue-pdp-image {
  height: 18px !important;
  width: 18px !important;
}

.orange-pdp-image {
  height: 18px !important;
  width: 18px !important;
}

.green-pdp-image {
  height: 17px !important;
  width: 17px !important;
}

@media (min-width: 568px) {
  .badge {
    height: 30px;
    .image {
      width: 15% !important;
    }
  }
}

@media (max-width: 361px) {
  .image {
    position: absolute !important;
  }

  // badges
  .red-badge {
    > span {
      margin-left: 15px !important;
    }
  }

  .orange-badge {
    > span {
      margin-left: 20px !important;
    }
  }

  .blue-badge {
    > span {
      margin-left: 20px !important;
    }
  }

  .purple-badge {
    > span {
      margin-left: 20px !important;
    }
  }

  .green-badge {
    > span {
      margin-left: 20px !important;
    }
  }

  .light_blue-badge {
    > span {
      margin-left: 20px !important;
    }
  }
  .yellow-badge {
    > span {
      margin-left: 20px !important;
    }
  }

  // images
  .red-image {
    left: 1px;
  }

  .blue-image {
    top: 0 !important;
    left: 2px;
  }
}
