.bought-together-slider {
  width: 100%;
  padding-bottom: 75px;

  .box {
    display: flex;
    margin-top: 30px;
  }
}

@media (max-width: 900px) {
  .bought-together-slider {
    padding: 15px;
    border-bottom: 1px solid var(--secondary-border-2);

    .box {
      margin-top: 15px;
    }
  }
}
