.declinedAddToCart-modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.declinedAddToCart-modal-content {
  width: 400px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background: var(--default-white);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header {
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
}

.button {
  margin-top: 28px;
}
