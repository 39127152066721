.favoriteButton-mobile {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  border: 1px solid #e2e2e2;
  background: transparent;
}

.favoriteButton-mobile__active {
  border-color: #f26b26;
}
