.wholesale-mobile-wrapper {
  display: flex;
  border-radius: 6px;
  border: 1px solid var(--secondary-border-1);
  margin-bottom: 15px;
  flex-direction: column;

  &:last-child {
    margin-bottom: 7.5px;
  }

  .product-information {
    border-bottom: 1px solid var(--secondary-border-1);
    display: flex;
    gap: 15px;
    padding: 10px;
    align-items: center;

    .image {
      border-radius: 4px;
      border: 0.5px solid var(--secondary-border-2);
    }

    .adjustment {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 2px;
    }

    .price {
      flex: 1;
      display: flex;
      flex-direction: column;

      div {
        text-align: right;
        display: flex;
        flex-direction: column;
        padding: 5px;
      }
    }
  }

  .product-code,
  .lot-number {
    display: flex;
    border-bottom: 1px solid var(--secondary-border-1);

    div {
      padding: 10px;
    }

    div:nth-child(1) {
      width: 100px;
      border-right: 1px solid var(--secondary-border-1);
    }
  }

  .lot-distribution {
    display: flex;
    border-bottom: 1px solid var(--secondary-border-1);
    div {
      padding: 10px;
    }
  }

  .lot-variations {
    padding: 10px;

    .table-container {
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        border-radius: 6px;
        background: var(--primary-primary-light);

        th,
        td {
          width: 68px;
          height: 33.5px;
          text-align: center;
          border: 1px solid var(--secondary-border-1);

          &:not(:last-child) {
            border-right: none;
          }

          &:last-child {
            max-width: 130px;
          }
        }

        th {
          &:first-child {
            border-top-left-radius: 6px;
          }
          &:last-child {
            border-top-right-radius: 6px;
          }
        }

        tbody {
          tr:last-child {
            td:first-child {
              border-bottom-left-radius: 6px;
            }
            td:last-child {
              border-bottom-right-radius: 6px;
            }
          }
        }
      }
    }
  }
}

.wholesale-adjustment {
  border: 1px solid var(--primary-primary);
}
