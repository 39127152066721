.product-sizes {
  .title {
    display: flex;
    justify-content: space-between;

    .size-chart {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .size-list {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }
}

@media (max-width: 900px) {
  .product-sizes {
    margin-top: 0;
    padding: 10px;
    border-bottom: 1px solid var(--secondary-border-2);
  }
}
