.container {
  display: flex;
  gap: 5px;
  padding: 6px;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ff430b;
  justify-content: space-between;
  border-radius: 4px;
  height: 26px;
}
