.productItem-mobile {
  width: 100%;
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
}

.productItem-mobile__details {
  display: flex;
  position: relative;
}

.productItem-mobile__image {
  width: 80px;
  height: 120px;
  border-radius: 4px;
}

.productItem-mobile__center {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.productItem-mobile__name {
  width: 87%;
  display: block;
}

.productItem-mobile__price {
  display: flex;
  align-items: center;
  gap: 1px;

  .discounted-price {
    text-decoration: line-through;
    color: var(--secondary-secondary) !important;
    font-size: 12px !important;
  }
}

.productItem-mobile__name-middle {
  display: flex;
  flex-direction: column;
}

.productItem-mobile__price__sub-sell-price {
  color: #f26b26;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.productItem-mobile__action-buttons__add-to-basket {
  position: absolute;
  right: 15px;
  bottom: 15px;
  display: flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  background: transparent;
  outline: none;
  border-radius: 6px;
  border: 1px solid #f26b26;

  span {
    color: #f26b26;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.productItem-mobile__error-message {
  color: #b00;
  font-family: 'Rubik';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: wrap;
  max-width: 140px;
  margin-bottom: 4px;
}

.badges {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;

  .last-x-badge-mobile-wrapper,
  .free-cargo {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
