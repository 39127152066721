.sizeChart-modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sizeChart-modal-content {
  width: 800px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  background: var(--default-white);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
  padding: 24px;
  display: flex;
}

.header {
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
}

.size-chart-svg {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 500px;
    height: 400px;
  }
}
