.container {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 6px;
  cursor: pointer;
  width: fit-content;

  span {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
  }
  svg {
    display: flex;
  }
}
.orange {
  background-color: var(--primary-primary-light);
  span {
    color: var(--primary-primary-dark);
  }
}
.info {
  background-color: var(--blue-links-blue-100);
  span {
    color: var(--blue-links-blue-500);
  }
}
.warning {
  background-color: var(--yellows-warning-yellow-100);
  span {
    color: var(--yellows-warning-yellow-500);
  }
}
.danger {
  background-color: var(--reds-errors-red-100);
  span {
    color: var(--reds-errors-red-500);
  }
}
.success {
  background-color: var(--greens-success-green-100);
  span {
    color: var(--greens-success-green-500);
  }
}

.active_orange {
  border: 1px solid var(--primary-primary-dark);
}
.active_info {
  border: 1px solid var(--blue-links-blue-500);
}
.active_warning {
  border: 1px solid var(--yellows-warning-yellow-500);
}
.active_danger {
  border: 1px solid var(--reds-errors-red-500);
}
.active_success {
  border: 1px solid var(--greens-success-green-500);
}
