$font-family: 'Rubik';

.main-header__search {
  position: relative;
  width: 854px;

  &__input-wrapper {
    display: flex;
    border-radius: 22px;
    border: 2px solid #f26b26;
    border-right: none;
    padding-left: 28px;
    height: 40px;
    width: 100%;
    position: relative;
    z-index: 102;
    background-color: var(--default-white);
  }

  &__result-wrapper {
    z-index: 101;
    position: absolute;
    width: 100%;
    top: 50%;
    background-color: var(--default-white);
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 30px 15px 15px;
    border-radius: 0 0 22px 22px;
    border: 2px solid var(--primary-primary);
  }

  .main-header__search-input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    background: transparent;
    color: #333;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &::placeholder {
      color: rgba(51, 51, 51, 0.5);
    }
  }

  .main-header__search-button {
    border: none;
    outline: none;
    border-radius: 22px;
    background: #f26b26;
    width: 125px;
    height: 100%;
    color: #fff;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .main-header__search-button-icon {
      padding: 3px;
    }
  }
}
