.sizeProduct-modal {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.sizeProduct-modal .modal-dialog {
  margin: 0;
}

.sizeProduct-modal__content {
  min-height: 200px;
  border-radius: 0;
  transform: translateY(100%);
  animation: slide-up 0.3s ease-out forwards;
}

@keyframes slide-up {
  to {
    transform: translateY(0);
  }
}

.sizeProduct-modal__product-details {
  display: flex;
  padding: 15px;
}

.sizeProduct__image {
  width: 55px;
  height: 82.29px;
}

.sizeProduct__close {
  position: absolute;
  right: 8px;
  top: 8px;
  button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    outline: none;
  }
}

.sizeProduct-modal__product-center {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.sizeProduct-modal__product-name {
  margin-bottom: 5px;
  span {
    text-overflow: ellipsis;
    color: #333;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
  }
}

.sizeProduct-modal__product-price {
  span {
    color: #333;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
  }
}

.sizeProduct-modal__size {
  margin-top: 15px;
  padding: 0 15px;
}

.sizeProduct-modal__size-title {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Rubik';
  color: #333;
  line-height: normal;
}

.sizeProduct-modal__size-cell__container {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  gap: 10px;
}

.sizeProduct-modal__size-cell {
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  box-sizing: border-box;
  color: #333;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  justify-content: center;
  letter-spacing: 1px;
  margin-bottom: 15px;
  min-width: 48px;
  position: relative;
  font-family: 'Rubik';
}

.sizeProduct-modal__size-cell__active {
  border: 1.5px solid #f26b26;
  color: #f26b26;
}

.sizeProduct-modal__add-to-basket__container {
  button {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    background: #f26b26;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 14px;
      text-transform: uppercase;
      color: #fff;
      font-family: 'Rubik';
    }
  }
}

.mobileAddToCartButton-container {
  height: 74px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 20000;
  background-color: white;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);

  &_total {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 10px;
  }
  &_approve {
    flex: 1;
    min-width: 200px;
  }
}

.mobileAddToCartButton-modal-container {
  display: flex;
  flex-direction: column;
}

.mobileAddToCartButton-modal {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 74px;
  left: 0;
  width: 100%;
  margin: 0;
}

.slide-down {
  animation: slide-down 0.4s ease-out forwards;
}

.mobileAddToCartButton-modal .modal-dialog {
  margin: 0;
}

.mobileAddToCartButton-modal__content {
  height: 280px;
  border-radius: 0;
  border: none;
  transform: translateY(100%);
  animation: slide-up 0.3s ease-out forwards;
  padding: 15px;
}

@keyframes slide-up {
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  to {
    transform: translateY(100%);
  }
}

.content {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid var(--secondary-border-2);
  padding-bottom: 20px;
  img {
    border-radius: 4px;
    border: 0.5px solid var(--secondary-border-2);
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top,
    .bottom {
      display: flex;
      flex-direction: column;
    }
  }
}

.badges {
  margin-bottom: 10.75px;
  display: flex;
  gap: 10px;
}

.last-x-badge-mobile-wrapper {
  width: 87px;
}

.free-cargo {
  width: 100px;
}

.product-sizes {
  margin-top: 20px;

  .title {
    display: flex;
    gap: 4px;
  }

  .size-list {
    margin-top: 12px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
}
