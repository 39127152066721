.mobileAddToCartButton-container {
  height: 74px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  background-color: white;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
  &_total {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    flex: 1;
  }
  &_approve {
    flex: 1;
    min-width: 255px;
  }
}

.mobileAddToCartButton-modal-container {
  display: flex;
  flex-direction: column;
}

.mobileAddToCartButton-modal {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 74px;
  left: 0;
  width: 100%;
  margin: 0;
}

.slide-down {
  animation: slide-down 0.4s ease-out forwards;
}

.mobileAddToCartButton-modal .modal-dialog {
  margin: 0;
}

.mobileAddToCartButton-modal__content {
  height: 235px;
  border-radius: 0;
  border: none;
  transform: translateY(100%);
  animation: slide-up 0.3s ease-out forwards;
  padding: 15px;
}

@keyframes slide-up {
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  to {
    transform: translateY(100%);
  }
}

.content {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;

  img {
    border-radius: 4px;
    border: 0.5px solid var(--secondary-border-2);
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top,
    .bottom {
      display: flex;
      flex-direction: column;
    }
  }
}

.badges {
  margin-bottom: 10.75px;
  display: flex;
  gap: 10px;
}

.last-x-badge-mobile-wrapper {
  width: 87px;
}

.free-cargo {
  width: 100px;
}

.lot-adjustment {
  display: flex;
  align-items: center;
  gap: 10px;

  .buttons {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
  }

  .lot-information {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
