.mobileAddToCartButton-container {
  height: 74px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  background-color: white;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
  gap: 10px;

  &_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
  }

  &_total_discount {
    color: var(--secondary-secondary) !important;
    text-decoration: line-through;
    font-size: 12px !important;
  }
  &_approve {
    flex: 1;
    min-width: 200px;
  }
}

.mobileAddToCartButton-modal-container {
  display: flex;
  flex-direction: column;
}

.mobileAddToCartButton-modal {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 74px;
  left: 0;
  width: 100%;
  margin: 0;
}

.slide-down {
  animation: slide-down 0.4s ease-out forwards;
}

.mobileAddToCartButton-modal .modal-dialog {
  margin: 0;
}

.mobileAddToCartButton-modal__content {
  border-radius: 0;
  border: none;
  transform: translateY(100%);
  animation: slide-up 0.3s ease-out forwards;
  padding: 15px;
}

@keyframes slide-up {
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  to {
    transform: translateY(100%);
  }
}

.content {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid var(--secondary-border-2);
  padding-bottom: 20px;
  img {
    border-radius: 4px;
    border: 0.5px solid var(--secondary-border-2);
  }
  .name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top,
    .bottom {
      display: flex;
      flex-direction: column;

      .total_discount {
        color: var(--secondary-secondary) !important;
        text-decoration: line-through;
        font-size: 12px !important;
      }
    }
  }
}

.badges {
  margin-bottom: 10.75px;
  display: flex;
  gap: 10px;
}

.last-x-badge-mobile-wrapper {
  width: 87px;
}

.free-cargo {
  width: 100px;
}

.product-sizes {
  margin-top: 20px;

  .title {
    display: flex;
    gap: 4px;
  }

  .size-list {
    margin-top: 12px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
}
