.product-colors {
  margin-top: 32px;

  .color-list {
    margin-top: 10px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    overflow-y: hidden;

    > a {
      height: fit-content;
    }

    .active {
      border: 2px solid var(--primary-primary);
      border-radius: 4px;
    }

    .color-image {
      cursor: pointer;
      border-radius: 4px;
      border: 0.5px solid var(--secondary-border-2);
    }
  }
}

@media (max-width: 900px) {
  .product-colors {
    margin-top: 0;
    padding: 10px;
    border-bottom: 1px solid var(--secondary-border-1);

    .color-list {
      gap: 10px;
      flex-wrap: nowrap;
      overflow-x: auto;
    }

    .color-list::-webkit-scrollbar {
      height: 0;
      width: 0;
      display: none;
    }
  }
}
