.modalExplanation {
  font-size: 14px;
  font-weight: 500;
  display: contents;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: unset;
}
