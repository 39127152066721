.container {
  padding: 2px;
  display: flex;
  flex-direction: column;

  min-width: 28px;
  border-radius: 4px;
  border: 0.5px solid #666;
  text-align: center;
  :first-child {
    border-bottom: 0.5px solid #666;
    padding-bottom: 2px;
  }

  :last-child {
    border-top: 0.5px solid transparent;

    padding-top: 2px;
  }
}

@media (max-width: 406px) {
  .container {
    min-width: 28px;
  }
}

@media (min-width: 406px) {
  .container {
    min-width: 38px;
  }
}
