.not_found {
  padding: 15px;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    gap: 12px;
    border-radius: 8px;
  }
}

@media (max-width: 500px) {
  .not_found {
    min-height: calc(100dvh - 315px);

    .container {
      border: 1px solid var(--secondary-border-1);
      text-align: center;
    }
  }
}
