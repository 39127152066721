.favorite-button {
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--secondary-border-2);
  background: var(--default-white);
  box-sizing: border-box;
  flex-shrink: 0;

  svg {
    display: flex;
  }
}

.favorite-button-active {
  border: 1px solid var(--primary-primary);
}
