.container {
  display: flex;
  align-items: center;
  width: 100%;
  .badge {
    background-color: #fff;
    padding: 6px 4px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 20px;
    width: 100%;
    display: flex;
    line-height: 1;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: visible;

    .image {
      position: relative;
      z-index: 10;
      left: -2px;
      height: 20px;
      width: 20px;
    }
    > span {
      text-align: center;
    }
  }
  .price {
    background-color: #fff;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    min-width: 66px;
    border: 1px solid var(--tofisav2-red-100);

    > div {
      line-height: 1;
    }
  }
}

.red-price {
  span {
    color: #ff1607 !important;
  }
}

.blue-price {
  border: 1px solid #084298 !important;
  span {
    color: #0a58ca !important;
  }
}

.purple-price {
  border: 1px solid #8f07b6 !important;
  span {
    color: #8f07b6 !important;
  }
}

.orange-price {
  border: 1px solid var(--primary-primary) !important;

  span {
    color: var(--primary-primary) !important;
  }
}

.light_blue-price {
  border: 1px solid #006dc9 !important;

  span {
    color: #006dc9 !important;
  }
}

.green-price {
  border: 1px solid #0fb607 !important;
  span {
    color: #0fb607 !important;
  }
}

.yellow-price {
  border: 1px solid #b86712 !important;
  span {
    color: #b86712 !important;
  }
}

@media (min-width: 568px) {
  .container {
    .price {
      min-width: unset;
      width: fit-content;
    }
  }

  .image {
    width: 15% !important;
  }
}

@media (max-width: 361px) {
  .price {
    padding: 4px 2px !important;
    height: 27px;
    min-width: 62px !important;

    :first-child {
      font-size: 12px !important;
    }
    :last-child {
      font-size: 8px !important;
    }
  }

  .image {
    width: 15% !important;
  }
}
