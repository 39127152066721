.footer {
  padding-top: 20px;
  text-align: center;
  padding-bottom: 37px;

  .links {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    .link_wrapper {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
    }

    .link {
      a {
        display: block;
        margin-top: 3px;
        > button {
          border: 1px solid var(--secondary-border-1);
          border-radius: 43px;
          padding: 4px 10px !important;
          height: unset;
        }
      }
    }
  }
  p {
    margin: 0;
  }

  .company {
    padding: 10px 10px 0 10px;
  }

  .description {
    padding: 4px 10px;
    text-align: center;
  }

  .rights {
    padding: 10px;
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .footer {
    background-color: rgb(249, 249, 249);
    padding-bottom: 17px;

    .links {
      flex-direction: column;
      margin-bottom: 15px;
    }
  }
}
