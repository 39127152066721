.kdv {
  display: flex;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid #abb5be;
  width: fit-content;
  > span {
    margin-bottom: 4px;
    margin-left: 2px;
  }
}

.total {
  display: flex;
  align-items: baseline;
  flex: 1;
  gap: 3px;
  justify-content: flex-start;
  width: 100%;
}
