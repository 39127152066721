.price-wrapper {
  white-space: nowrap;

  > span {
    line-height: 1;
  }
}

.gradient {
  background: linear-gradient(90deg, #feae00 0%, #ff1607 73.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.discounted-price {
  text-decoration: line-through;
  color: var(--secondary-secondary) !important;
  font-size: 12px !important;
}

.currency {
  white-space: nowrap;
}
