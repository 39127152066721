.count-down {
  background: #fff;
  padding: 5px 6px 4px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  position: relative;

  gap: 5px;
  * {
    color: #ff1607;
  }

  .timer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;

    .colon {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 4px;
      gap: 1px;
      div {
        width: 1px;
        height: 1px;
        border-radius: 0.2px;
        background: var(--tofisav2-red-100);
      }
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        line-height: 1;
      }
    }
  }

  .firecracker {
    position: absolute;
    top: -9px;
    left: 2px;
  }
}

.lg {
  .colon {
    div {
      width: 3px !important;
      height: 3px !important;
    }
  }
}

@media (min-width: 567px) {
  .count-down {
    height: 32px;

    .text {
      font-size: 6px;
    }
  }
  .firecracker {
    top: -2px !important;
  }
}
