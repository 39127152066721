.search-text {
  color: var(--primary-primary);
  padding: 15px 15px 20px;
  display: block;
  &--mobile {
    padding-bottom: 10px;
  }
}

.search-results {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  max-height: 250px;
  overflow-y: scroll;

  &--mobile {
    max-height: unset;
    overflow-y: unset;
  }

  &__loading,
  &__empty-message {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: center;
  }

  &__loading {
    svg {
      animation: rotate 2s linear infinite;
    }
  }

  &__item {
    padding: 10px 15px;
    display: grid;
    grid-template-columns: 12px 1fr 130px;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--secondary-border-1);
    width: 100%;
    &:last-child {
      border-bottom: none;
    }

    p {
      margin-bottom: 0;
    }

    &__type {
      color: var(--secondary-secondary-light);
      text-align: right;
    }

    &__title,
    &__type {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }

    &:hover {
      background-color: rgba(226, 226, 226, 0.5);
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
