.product-name {
  border-bottom: 1px solid var(--secondary-border-2);

  .name-and-tag {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    .name {
      flex: 1;
    }
  }

  .reviews-wrapper {
    display: flex;
    margin-top: 8px;
    gap: 16px;

    .stars {
      display: flex !important;
      align-items: center !important;
      gap: 4px;

      span {
        line-height: 1px;
        display: block;
        width: 70px;
        height: 14px;
        overflow: hidden;
      }

      .review-count {
        margin: 0;
      }
    }

    .comments {
      display: flex;
      align-items: center;
      gap: 4px;
      border-right: 1px solid var(--secondary-border-1);
      border-left: 1px solid var(--secondary-border-1);
      padding: 0 16px;
    }

    .favorites {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .price-wrapper {
    margin: 16px 0;
    padding: 10px 0;

    .discounted-price {
      text-decoration: line-through;
      color: var(--secondary-secondary) !important;
      font-size: 20px !important;
      margin-right: 10px;
    }
  }
}

.product-name-wholesale {
  border-bottom: none;
}

.lowest-price-label {
  width: 260px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--reds-errors-red-600);
}

.lowest-price-label-badge {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: var(--reds-errors-red-100);

  svg {
    display: flex;
  }
}

.last-x-badge-mobile-wrapper {
  width: 87px;
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (max-width: 900px) {
  .product-name {
    padding: 15px;
  }

  .reviews-wrapper {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      gap: 8px;

      .comments {
        border: none;
        padding-left: 8px;
        border-left: 1px solid var(--secondary-border-1);
      }
    }
  }
}
