.action-buttons {
  margin-top: 23px;
  display: flex;
  gap: 16px;
  align-items: center;
  height: 48px;
}

.action-buttons-wholesale {
  margin-top: 20px;
}

.wholesale-total {
  width: 210px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}
