.product-name {
  border-bottom: 1px solid var(--secondary-border-2);
  padding: 10px;

  .name-and-badge {
    :first-child {
      border-radius: 4px;

      width: fit-content;
      float: left;
      > div {
        position: relative;
        width: 20px;
        left: unset;
      }
      span {
        text-align: right;
        font-size: 10px;
        margin-left: 5px;
      }
    }

    .name {
      margin-left: 5px;
    }
    .promotion {
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding: 0 4px;
      float: none;
      width: 100%;
      gap: 2px;
    }
  }
}
