.product-slider {
  user-select: none;
  display: flex;

  > div > div {
    margin-right: 24px;
  }

  .swiper-slide-mobile {
    width: calc(((100% - 2 * 16px) / 2.12)); // 16px is the spaceBetween of the swipperSlider.
  }

  .swiper-slide-desktop {
    width: 230.8px; // this width reached from the swipper slider after its calculation and hydration.
  }
}

@media (max-width: 500px) {
  .product-slider {
    > div > div {
      margin-right: 16px;
    }
  }
}
